.book-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 400px;

    // border: 3px rgb(255, 136, 0) solid;
    .testemunho {
        margin: 2%;
        margin-top: 10%;
        width: 360px;
        height: 360px;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        align-items: center;
        // border: 1px black solid;
        img {
            max-width: 150px;
            max-height: 25px;
        }
        p {
            text-align: center;
            margin-top: 5%;
            @import url("https://fonts.googleapis.com/css?family=IM+Fell+French+Canon");
            font-family: "IM Fell French Canon", serif;
            font-weight: bold;
            // text-transform: uppercase;
            text-align: center;
            font-size: 18px;
            letter-spacing: 1px;
            color: rgb(0, 0, 0);
        }
    }
    .book {
        // border: 1px black solid;
        width: 300px; /* or any custom size */
        height: 450px;
        img {
            width: 300px;
            height: 450px;
            object-fit: cover;
            -webkit-box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
            box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
        }
    }
    @media (max-width: 768px) {
        height: auto;
        .testemunho {
            display: none;
        }
        .book {
            width: 200px;
                height: 320px;
            // border: 3px rgb(251, 255, 0) solid;

            img {
                width: 200px;
                height: 320px;
            }
        }

        // position: absolute;
    }
}
