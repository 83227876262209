.why-section {
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #d4b668;
    width: 100%;
    height: auto;
    margin-top: 10%;
    .why-title {
        // margin-top: 10%;
        max-width: 700px;
        // border: 1px solid black;
        p {
            margin-top: 8%;
            @import url("https://fonts.googleapis.com/css?family=Quattrocento");
            font-family: Quattrocento, serif;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            font-size: 2.5em;
            letter-spacing: 3px;
            word-spacing: 1px;
            color: rgb(0, 0, 0);
            // background-color: rgb(84, 84, 84);
            text-shadow: rgb(0, 0, 0) 2px 14px 20px;
            line-height: 1.5;
        }
    }
    .why-video {
        margin-top: 5%;
        // border: red solid 5px;
        width: 90%;
        height: auto;
        iframe {
            height: 600px;
            width: 100%;
        }
    }
    .why-reasons {
        margin-top: 5%;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title {
            text-align: center;
            @import url("https://fonts.googleapis.com/css?family=Trocchi");
            font-family: Trocchi, serif;
            font-weight: bold;
            text-transform: uppercase;
            text-align: left;
            font-size: 25px;
            letter-spacing: 1px;
            color: rgb(0, 0, 0);
        }
        .why-check-container {
            // border: 3px solid green;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: center;
            height: 450px;
            width: 100%;
            .item-check {
                // border: solid 1px black;
                height: 100px;
                max-width: 500px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                img {
                    height: 20px;
                    width: 20px;
                }
                p {
                    margin: 5%;
                    @import url("https://fonts.googleapis.com/css?family=Trocchi");
                    font-family: Trocchi, serif;
                    font-weight: bold;
                    // text-transform: uppercase;
                    text-align: left;
                    font-size: 17px;
                    letter-spacing: 1px;
                    color: rgb(0, 0, 0);
                }
            }
        }
    }
    @media (max-width: 768px) {
        .why-title {
            p {
                font-size: 1.6em;
                margin: 10px;
            }
        }
        .why-video {
            margin-top: 5%;
            // border: red solid 5px;
            width: auto;
            height: auto;
            iframe {
                height: 400px;
                width: 100%;
            }
        }
        .why-reasons {
            .title {
                font-size: 1.5em;
                text-align: center;
            }
            .why-check-container {
                height: auto;
                .item-check {
                }
            }
        }
    }
}
