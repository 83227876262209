.circle-display {
    margin-top: 2%;
    // background-color: aqua;
    border-radius: 10px;
    // border: solid 1px green;
    width: 90%;
    height: auto;
    // max-height: 70px;
    -webkit-box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.55);
    box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.398);
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;
    // cursor: pointer;

    .ball-title-modal-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        // border: solid 1px black;
        height: auto;

        // .ball-title-container {
        //     // border: solid 1px black;
        //     width: 100%;
        //     display: flex;
        //     align-items: center;
        // justify-content:space-around;

        .ball {
            // border: solid 1px red;
            margin: 2%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            overflow: hidden;
            background-image: url("/img/Relatorio_Icon.png");
            background-size: cover;
            background-position: center;
            width: 50px;
            height: 50px;
            p {
                font-size: 2.3rem;
                font-weight: bold;
                color: #bc9b40;
                font-weight: bold;
                color: #bc9b40;
                text-shadow: -1px 1px 10px rgba(0, 0, 0, 1);
                // box-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
                position: relative; // necessário para aplicar z-index
                // z-index: 10; // equivalente a elevation no React Native
            }
        }

        .title {
            // border: solid 1px white;
            margin: 3%;
            height: auto;
            display: flex;
            align-items: center;
            p {
                font-size: 2.3rem;
                color: "#BC9B40";
                text-align: center;
                font-size: 2.3rem;
                font-weight: bold;
                color: #bc9b40;
                font-weight: bold;

                text-shadow: -1px 1px 10px rgba(0, 0, 0, 1);
                // box-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
                position: relative;
            }
        }
        // }

        h3 {
            // border: solid 1px red;
            margin-right: 1%;
            // position:fixed;
            font-size: 2rem;
            color: rgb(117, 116, 107);
            background-color: transparent;
            cursor: pointer;
        }
    }
    .description-container {
        // border: solid 1px red;
        width: 100%;
        // height: auto;
        max-height: 0px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;

        &.open {
            max-height: 700px; // Altere isso para se adequar ao conteúdo

            .description-text-container {
                .description {
                    opacity: 1;
                }
            }
        }
        .description-text-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            // background-color: #79767664;
            background-color: #0a1a447a;
            border-radius: 10px;
            padding: 2%;

            .description {
                // border: solid 1px yellow;
                margin: 1%;
                font-size: 1.6rem;
                font-weight: bold;
                text-align: center;
                // color: #ffffff;
                // text-shadow: 2px 2px 2px #ffffff47;
                color: #bc9b40;
                // background: #7976765e;
                text-shadow: 4px 3px 0 #403f3f;
                opacity: 0;
                transition: opacity 0.5s ease-in-out;
            }
            .text-description {
                // background-color: #7976765e;
                // border-radius: 10px;

                padding: 3%;
                font-family: Georgia, serif;
                font-size: 16px;
                letter-spacing: 1.2px;
                word-spacing: 1px;
                color: #d6d6d6;
                font-weight: bold;
                text-decoration: none;
                font-style: italic;
                font-variant: small-caps;
                text-transform: none;
            }
            .tabela{
                // border: solid 2px red;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                table {
                    width: 80%;
                    border-collapse: collapse;

                    th,
                    td {
                        padding: 10px;
                        text-align: center;
                        border: 1px solid #dddddd83;
                        color: white;
                        font-weight: bolder;

                    }

                    th {
                        background-color: #4caf4f5d;
                        color: white;
                        
                    }

                    tr:nth-child(even) {
                        background-color: #f2f2f23c;
                        color: white;
                        font-weight: bolder;

                    }

                    tr:hover {
                        background-color: #dddddd69;
                    }
                }
            }
        }
        .book-link-container {
            // border: solid 2px red;
            display: flex;
            align-items: center;
            // justify-content: center;
            height: auto;

            p {
                @import url("https://fonts.googleapis.com/css?family=Lora");
                font-family: Lora, serif;
                font-weight: bold;
                text-align: center;
                font-size: 1.4em;
                // text-transform: uppercase;
                // letter-spacing: 3px;
                color: rgb(234, 234, 234);
                // text-shadow: rgba(161, 161, 161, 0.4) 2px 2px;
            }

            img {
                width: 50%;
                height: auto;
                transition: transform 0.3s ease;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }

    @media (max-width: 768px) {
        margin-top: 4%;
        .ball-title-modal-container {
        }
        .description-container {
            .book-link-container {
                p {
                    font-size: 1.2em;
                }

                img {
                    width: 80%;
                }
            }
        }
    }
}
