.info-section {
    // margin-top: 1%;
    // border: 1px black solid;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 800px;
    height: 400px;
    text-align: center;
    .info-title {
        margin: 1%;
        @import url("https://fonts.googleapis.com/css?family=Quattrocento");
        font-family: Quattrocento, serif;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        font-size: 2.3em;
        letter-spacing: 6px;
        word-spacing: 1px;
        color: rgb(0, 0, 0);
        // background-color: rgb(84, 84, 84);
        text-shadow: rgb(0, 0, 0) 2px 14px 20px;
        line-height: 1.1;
    }
    .info-description {
        margin-top: 4%;
        @import url("https://fonts.googleapis.com/css?family=Lora");

        font-family: Lora, serif;
        // font-weight: bold;
        // text-transform: uppercase;
        text-align: center;
        font-size: 1.8em;
        letter-spacing: 3px;
        color: rgb(0, 0, 0);
        text-shadow: rgba(161, 161, 161, 0.4) 2px 2px
    }
    @media (max-width: 768px) {
        width: 100%;
        height: auto;
        margin-top: 2%;
        .info-title{
            font-size: 1.8em;
        }
        .info-description{
            font-size: 1.3em;
        }
    }
}
