.modal-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.506);
    z-index: 3;
    .modal {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 10px;
        background-color: #fff;
        padding: 10px;
        border-radius: 8px;
        width: 90%;
        text-align: center;

        h1{
            font-size: 1.6rem;
        }
        .text-container{

            p {
                // width: 70%;
                color: black;
                line-height: 15px;
            }
    
            button {
                // margin-left: 2%;
                height: 30px;
                width: auto;
                padding: 5px 10px;
                background-color: #333;
                color: #fff;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                margin-left: 10%;
            }

            .form-check {
                display: flex;
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 780px) {
    .modal {
        .modal-text {
            // display: none;
        }
    }
}
