.relatorio-title {
    width: 90%;
    // border: solid 1px white;
    // margin-top: 10%;
    
    h1 {
        text-align: center;
        @import url("https://fonts.googleapis.com/css?family=Lora");
        font-family: Lora, serif;
        font-size: 3.8em;
        color: #bc9b40;
        text-shadow: 4px 3px 0 #403f3f;
        text-shadow: -1px 1px 10px rgba(0, 0, 0, 1);
        padding: 2%;
        // font-weight: bold;
        // color: rgb(0, 0, 0);
        // text-shadow: rgba(161, 161, 161, 0.4) 2px 2px;

        // font-weight: bold;
        // color: #ffffff;
        // text-shadow: 2px 2px 2px #ffffff47;
        // background: #7976765e;
        // opacity: 0;
        // transition: opacity 0.5s ease-in-out;

        // font-size: 2.3rem;
        

    }
    p {
        text-align: center;
        margin: 3%;
        font-size: 2em;
        font-weight: bold;
    }

    @media (max-width: 768px) {
        // margin-top: 30%;

        h1 {
            font-size: 1.8em;
        }
        p {
        }
    }
}

.div-circle-container {
    // border: solid 1px green;
    // min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

// button {
//     padding: 10px;
//     font-size: 1.6rem;
//     border: none;
//     border-radius: 5px;
//     margin-top: 20px;
//     background-color: rgb(132, 124, 79);
//     color: white;
// }
h3 {
    // position:fixed;
    font-size: 3rem;
    color: rgb(117, 116, 107);
}
