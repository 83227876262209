.burger-container {
    position: relative;
    // top: 30px;
    left: 30px;
    z-index: 3;
    // margin-left:5% ;
    // -webkit-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
    // -moz-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
    // box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);

    .burger-button {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 25px;
        background: transparent;
        border: none;
        cursor: pointer;
        transition: transform 0.3s ease;

        .line {
            width: 100%;
            height: 3px;
            // background-color: black;
            background-color: rgb(0, 0, 0);
            margin: 2px 0;
            transition: background-color 0.3s ease;
            // background-image: url(../../img/gold-line.png);
        }

        &.open {
            .line:nth-child(1) {
                transform: translateY(8px) rotate(45deg);
            }

            .line:nth-child(2) {
                opacity: 0;
            }

            .line:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
            }
        }
    }

    .menu {
        position: absolute;
        margin: 10px;
        border: black solid 1px;
        width: 200px;
        height: auto;
        // background-color: #fff;
        background-color: #d4b568ec;

        border-radius: 10px;

        display: flex;
        flex-direction: column;

        -webkit-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 10px 25px 0px rgba(0, 0, 0, 0.75);

        p {
            // border: 1px solid black;
            // border-radius: 10px;
            cursor: pointer;
            padding: 5%;
            margin-top: 4%;
            @import url("https://fonts.googleapis.com/css?family=Lora");
            font-family: Lora, serif;
            font-weight: bold;
            font-size: 1.5em;
            letter-spacing: 4px;
            color: rgb(0, 0, 0);
            text-transform: uppercase;
        }
        p:hover {
            // background-color: #0a1a44; /* Altere a cor de fundo para o estilo que você desejar */
            // color: #b7b8bc; /* Altere a cor do texto para o estilo que você desejar */
            // border: 1px solid black; /* Adicione uma borda ou estilos adicionais conforme necessário */
            border-radius: 10px;
            transition: transform 0.3s ease;
            transform: scale(1.1);
            transform: translateX(20px);
            text-decoration: underline solid rgb(68, 68, 68);
        }
        button {
            margin: 3px;
        }
    }
}
