.modal-error {
    position: absolute;
    width: auto;
    height: auto;
    background-color: rgb(228, 227, 225);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgb(66, 66, 66);
    border-radius: 10px;
    // shadow
    -webkit-box-shadow:
        inset -1px 3px 8px 5px #5c5d5d,
        2px 5px 16px 0px #535353,
        0px 0px 24px 10px rgba(0, 0, 0, 0.4);
    box-shadow:
        inset -1px 3px 8px 5px #dddee0,
        2px 5px 16px 0px #555555,
        0px 0px 24px 10px rgba(0, 0, 0, 0.4);
    // background: #1c6ea4;
    h1 {
        margin-top: 5%;
        font-size: 3em;
        color: rgb(255, 0, 0);
    }
    p {
        padding: 5%;
        text-align: center;
        font-weight: bold;
        font-size: 1.6em;
        color: #484848;
    }
    button {
        margin: 10px;
        background-color: aliceblue;
        color: black;
        min-width: 60px;
        transition: background-color 0.5s ease;

        &:hover {
            background-color: #67ae67; // Muda a cor do fundo quando o botão é pressionado

        }
        &:active {
            background-color: #d03333;
        }
    }
}
