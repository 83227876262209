.footer-container{
    margin-top: 10%;
    width: 100%;
    height: 50px;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    // position:absolute;
    bottom: 0;
    // margin: auto;
    
    a{
        text-decoration: none;
    }
    p{
        text-align: center;
        color: rgb(164, 162, 162);
    }
    Link{
        color: bisque;
    }

    @media (max-width: 768px) {
        height: 20px;
    }
}