.policy-container {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .policy-text-section {
        // border: 2px solid red;
        width: 1080px;

        h1 {
            margin: 10%;
            @import url("https://fonts.googleapis.com/css?family=Quattrocento");
            font-family: Quattrocento, serif;
            font-weight: bold;
            text-transform: uppercase;
            text-align: center;
            font-size: 34px;
            letter-spacing: 1px;
            word-spacing: 1px;
            color: rgb(0, 0, 0);
            // background-color: rgb(84, 84, 84);
            // text-shadow: rgb(0, 0, 0) 2px 14px 20px;
        }
        span {
            margin: 1%;
            @import url("https://fonts.googleapis.com/css?family=Quattrocento");
            font-family: Quattrocento, serif;
            font-weight: bold;
            // text-transform: uppercase;
            text-align: center;
            font-size: 28px;
            letter-spacing: 1px;
            word-spacing: 1px;
            color: rgb(0, 0, 0);
            // background-color: rgb(84, 84, 84);
            // text-shadow: rgb(0, 0, 0) 2px 14px 20px;
        }
        p {
            margin: 1%;
            @import url("https://fonts.googleapis.com/css?family=Quattrocento");
            font-family: Quattrocento, serif;
            // font-weight: bold;
            // text-transform: uppercase;
            // text-align: center;
            font-size: 24px;
            letter-spacing: 1px;
            word-spacing: 1px;
            color: rgb(0, 0, 0);
            // background-color: rgb(84, 84, 84);
            // text-shadow: rgb(0, 0, 0) 2px 14px 20px;
            line-height: 1.3;
            span {
                margin: 1%;
                @import url("https://fonts.googleapis.com/css?family=Quattrocento");
                font-family: Quattrocento, serif;
                font-weight: bold;
                // text-transform: uppercase;
                text-align: center;
                font-size: 24px;
                letter-spacing: 1px;
                word-spacing: 1px;
                color: rgb(0, 0, 0);
                // background-color: rgb(84, 84, 84);
                // text-shadow: rgb(0, 0, 0) 2px 14px 20px;
            }
        }
    }
}
