.relatorio-back-button {
    position: absolute;
    top: 30px;
    right: 140px;
    z-index: 3;
    button {
        background: none;
        border: none;
        h3 {
            background: none;
            color: gold;
        }
    }
}
