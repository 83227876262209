.corner-tab {
    // border: 3px solid red;
    // overflow: hidden;
    content: "";
    position: relative;
    top: 0;
    // bottom: 0;
    left: 88%;
    // right: 0px;
    // width: 18%;
    // height: 250px;
    // background-color: #d4b668;
    // transform: skew(50deg);
    z-index: 1;
    @media (max-width: 768px) {
        left: 80%;
    }
}
