.header-section {
    // border: lime solid 3px;
    // overflow: hidden;
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .stamp {
        // border: 1px solid red;
        // position: relative;
        width: auto;
        height: 100%;
        // left: 80%;
        z-index: 1;
        img {
            width: auto;
            height: 100%;
        }
    }
    .amazon-container {
        // border: 1px solid red;
        width: 1080px;
        position: absolute;
        // top: 0;
        // left: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        // z-index: 2;
        .line {
            // border: 1px solid red;
            height: 2px;
            width: 600px;
            background-color: rgba(54, 52, 36, 0.428);
            -webkit-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
            box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
            background-image: url(../img/gold-line.png);
        }
        img {
            // border: 1px solid black;
            // margin: 10%;
            height: 50px;
            width: 140px;
            border-radius: 10px;
            background-color: #fff;
            -webkit-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
            box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
        }
    }

    @media (max-width: 768px) {
        height: 100px;
        // .stamp {
        //     left: 42%;
        //     img {
        //         width: auto;
        //         height: 50%;
        //     }
        // }
        .amazon-container {
            width: 100%;
            .line {
                display: none;
            }
            img {
                height: 30px;
                width: 90px;
            }
        }
    }
}
