.step2-section {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;
    width: 100%;
    // border: solid black 3px;
    p {
        @import url("https://fonts.googleapis.com/css?family=Trocchi");
        font-family: Trocchi, serif;
        font-weight: bold;
        // text-transform: uppercase;
        text-align: center;
        font-size: 2.3em;
        letter-spacing: 2px;
        color: rgb(0, 0, 0);
        
        
    }
    .step2-qr-container{
        display: flex;
        flex-direction: row;
        // border: solid 1px red;

        width: 100%;
        align-items: center;
        justify-content: center;
        .qr-container{
            // border: solid 1px red;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5%;
            a {
                margin-top: 5%;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 1em;
                line-height: 30px;
                transition: transform 0.3s ease;

                &:hover {
                    transform: scale(1.1);
                }
                .play-store {
                    border-radius: 10px;
                    width: 200px;
                    height: 80px;
                    background-color: #ffffff00;
                    transition: transform 0.3s ease;
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
            .qr-code {
                border-radius: 10px;
                margin-top: 5%;
                width: 300px;
                height: 300px;
                background-color: #fff;
                transition: transform 0.3s ease;
                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
    @media (max-width: 768px) {
        height: auto;
        margin: 1rem;
        p{
            margin: 10px;
            font-size: 1.5em;
        }
        .step2-qr-container{
        flex-wrap: wrap;

            .qr-container{
                a{
                    .play-store{
                        width: 150px;
                        height: 60px;
                    }
                }
                .qr-code{
                    width: 180px;
                    height: 180px;
                }
            }
        }
    }
}
