.book-alone {
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        width: 300px;
        height: 450px;
        object-fit: cover;
        -webkit-box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
    }
}
