// Estilos dos títulos
.calculator-titles {
    // border: solid 2px red;
    width: 90%;
    // padding: 2%;
    // margin-top: 20%;
    // font-weight: bold;
    text-align: center;
    
    // background: #e8e8e8;
    
    p:nth-child(1) {
        // padding: 1%;
        margin: 0;
        font-size: 4em;
        color: #d9d9d9;
        // background: #e8e8e8;
        text-shadow:
            -1px -1px 1px rgba(255, 255, 255, 0.1),
            1px 1px 1px rgba(0, 0, 0, 0.5);
        color: #d9d9d9;
    }
    p:nth-child(2) {
        margin-top: 2%;
        font-size: 1.4em;
        text-transform: uppercase;
        letter-spacing: 2px;
        font-weight: 500;
    }
}

// Estilos das instruções
.calculator-instructions {
    margin-left: 10%;
    margin-right: 10%;
    // margin-top: 20px;

    h2{
        font-size: 1.9em;
        text-align: center;
    }
    p {
        font-size: 2.4rem;
        // font-weight: bold;
        line-height: 35px;
        
    }
}

// Estilos do formulário
.calculator-form {
    // border: 2px solid red;
    width: 100%;
    display: flex;
    flex-direction: column;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    label {
        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 10px;
    }

    input {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #ccc;
        font-size: 1.6rem;
        margin-top: 5px;
        width: 50%;
    }

    button {
        padding: 10px;
        font-size: 1.6rem;
        border: none;
        border-radius: 5px;
        margin-top: 20px;
        background-color: #ac7c3e;
        color: white;
        text-transform: uppercase;
    }

    .calculator-name-input {
        // border: 2px solid red;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .calculator-date-input {
        // border: 2px solid red;
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
}

// Estilos para dispositivos móveis
@media screen and (max-width: 780px) {
    .calculator-titles {
        // border: solid 2px red;
        font-size: 2.3rem;
        margin-bottom: 2%;
        p:nth-child(1) {
            font-size: 1.2em;
        }

        p:nth-child(2) {
            margin-top: 2%;
            font-size: 1.4rem;
            
        }
    }

    .calculator-instructions {
        h2{
            font-size: 1.4em;
        }
        p {
            font-size: 1.8rem;
            line-height: 20px;
        }
    }

    .calculator-form {
        label {
            font-size: 1.4rem;
        }

        input {
            font-size: 1.4rem;
            width: 90%;
        }

        button {
            font-size: 1.4rem;
        }
    }
}
