.main-container {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 100vw;
    min-height: 100vh;
    background: -moz-linear-gradient(-45deg, #d4b668 0%, #0a1a44 100%);
    background: -webkit-linear-gradient(-45deg, #d4b668 0%, #0a1a44 100%);
    background: linear-gradient(0deg, #d4b668 0%, #0a1a44 100%);
    
    // border: hsl(58, 100%, 50%) solid 5px;
    
    .grid-container {
        // border: red solid 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 1080px;
        min-height: 100vh;
        // background-color: aliceblue;
        -webkit-box-shadow: 0px 2px 21px 10px rgba(0, 0, 0, 0.4);
        box-shadow: 0px 2px 21px 10px rgba(0, 0, 0, 0.4);
        background: #d4b668;
        background: -moz-linear-gradient(left, #d4b668 0%, #0a1a44 100%);
        background: -webkit-linear-gradient(left, #d4b668 0%, #0a1a44 100%);
        background: linear-gradient(to right, #d4b668 0%, #0a1a44 100%);
        z-index: 1;
    }

    @media (max-width: 768px) {
        // width: auto;
        .grid-container{
            width: 100vw;
        }
    }
}