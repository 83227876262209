.decoration-line-container {
    // border: solid red 2px;
    position: absolute;
    top: 12%;
    z-index: 1;

    .decoration-line {
        // border: solid green 2px;
        top: 15px;
        height: 2px;
        width: 100vw;
        background-color: rgba(54, 52, 36, 0.428);
        -webkit-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
        box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.75);
        background-image: url(../../img/gold-line.png);
    }
}
