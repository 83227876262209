.step1-section {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 100%;
    background-color: #d4b668;
    // border: black solid 3px;
    p {
        margin-top: 2%;
        @import url("https://fonts.googleapis.com/css?family=Trocchi");
        font-family: Trocchi, serif;
        font-weight: bold;
        // text-transform: uppercase;
        text-align: center;
        font-size: 2.3em;
        letter-spacing: 2px;
        color: rgb(0, 0, 0);
        margin-left: 3%;
        margin-right: 3%;
    }
    a {
        margin-top: 5%;
        img {
            border-radius: 10px;
            width: 200px;
            height: 80px;
            background-color: #fff;
            -webkit-box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
            box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
            transition: transform 0.3s ease;
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    .book-options {
        // border: black solid 1px;
        margin-top: 2%;
        width: auto;
        
        display: flex;
        justify-content: center;
        align-items: center;
        div {
            // border: black solid 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 3%;
            background-color: #ff9900;
            text-align: center;
            width: 240px;
            height: 50px;
            border: 1px solid black;
            border-radius: 10px;
            -webkit-box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
            box-shadow: 10px 13px 39px 0px rgba(0, 0, 0, 0.75);
            transition: transform 0.3s ease;
            a{
                text-decoration: none;
                text-transform: uppercase;
                margin: 0;
                p{
                    font-size: 1.5em;
                }
            }
            &:hover {
                transform: scale(1.1);
            }
        }
    }
    @media (max-width: 768px) {
        p{
            font-size: 1.5em;
            // margin: 10px;
        }
        a{
            img{
                width: 100px;
                height: 40px;
            }
        }
        .book-options{
            div{
                width: 100px;
                a{
                    p{
                        font-size: 1em;
                    }
                }
            }
        }
    }
}
