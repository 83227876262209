* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

*,
::before,
::after {
    box-sizing: inherit;
}

html {
    font-size: 61.8%;
}

body {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 2rem;
}
